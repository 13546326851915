import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import NewsList from '../components/new-list'

class NewsIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulNews.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Nyheter" />
        <Hero title="Nyheter" />
        <NewsList posts={posts} />
      </Layout>
    )
  }
}

export default NewsIndex

export const pageQuery = graphql`
  query NewsIndexQuery {
    allContentfulNews(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "YYYY-MM-DD")
        description {
          raw
        }
      }
    }
  }
`
